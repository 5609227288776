import DefaultIcon from '../assets/icons/social/link.png';
import FacebookIcon from '../assets/icons/social/facebook.png';
import InstagramIcon from '../assets/icons/social/instagram.png';
import LinkedInIcon from '../assets/icons/social/linkedin.png';
import TelegramIcon from '../assets/icons/social/telegram.png';
import TwitterIcon from '../assets/icons/social/twitter.png';
import VKIcon from '../assets/icons/social/vk.png';
import WhatsappIcon from '../assets/icons/social/whatsapp.png';
import YoutubeIcon from '../assets/icons/social/youtube.png';


export default {
    default: DefaultIcon,
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    linkedin: LinkedInIcon,
    telegram: TelegramIcon,
    twitter: TwitterIcon,
    vk: VKIcon,
    whatsapp: WhatsappIcon,
    youtube: YoutubeIcon
};